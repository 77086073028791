
<template>
  <v-layout child-flex>

    <v-card v-if="tenantIsLoaded">
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <v-card shaped outlined class="pa-4 ma-2">
            <v-card-title>
              <TenantBreadcrumb v-bind:name="tenant.tenant_name" :link=false></TenantBreadcrumb>
              <v-spacer></v-spacer>
              <v-chip class="float-right text-uppercase" :color="deployColor(tenant.deployment_group)" text-color="white"
                v-model="tenant" v-if="tenant.deployment_group">
                {{ tenant.deployment_group }}
              </v-chip>


            </v-card-title>
            <v-row>
              <v-col cols="4" sm="12" lg="4">
                <table>
                  <tr>
                    <td>Region:</td>
                    <td>{{ tenant.region.description }}</td>
                  </tr>
                  <tr v-if="tenant.coursepro_version">
                    <td class="pt-1">Version:</td>
                    <td>{{ tenant.coursepro_version }}</td>
                  </tr>
                  <tr v-if="tenant.coursepro_tenant">
                    <td>Bridge:</td>
                    <td>
                      <Bridge v-bind:bridge="tenant.coursepro_bridge" v-bind:version="tenant.coursepro_version"></Bridge>
                      <v-btn small v-if="tenant.coursepro_bridge === 'legend'" @click="openSandbox()">Soap Sandbox</v-btn>
                      <v-btn small v-if="tenant.coursepro_bridge === 'xnrest'" @click="openLeisureHub()">LeisureHub
                        Sandbox</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>DB Host label:</td>
                    <td><v-chip class="ma-2" color="pink" small label text-color="white">
                        <v-icon left>
                          mdi-database
                        </v-icon>
                        {{ tenant.db_host_label }}
                      </v-chip></td>
                  </tr>
                  <tr v-for="rel in tenant.relations" :key="rel.idx">
                    <td>{{ getRelationType(rel.type) }}</td>
                    <td><a :href=rel.tenant_right>{{ rel.tenant_right_name }}</a></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <v-btn small @click="manageRelations()">Manage Relations</v-btn>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="12" sm="12" lg="2">

              </v-col>
              <v-col cols="12" sm="12" lg="6">
                <v-row>
                  <v-col cols="8" class="text-right pa-0">Production (ie not a training or test site)</v-col>
                  <v-col cols="4" class="text-left pa-0">
                    <v-switch class="pa-0 mt-0 ml-4" dense v-model="tenant.production"
                      @change="toggleStaging()"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8" class="text-right pa-0">Active</v-col>
                  <v-col cols="4" class="text-left pa-0">
                    <v-switch class="pa-0 mt-0 ml-4" dense v-model="tenant.status" @change="toggleStatus()"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8" class="text-right pa-0">Cron active</v-col>
                  <v-col cols="4" class="text-left pa-0">
                    <v-switch class="pa-0 mt-0 ml-4" dense v-model="tenant.cron_active" @change="toggleCron()"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12" sm="12" lg="12"
          v-if="tenantIsLoaded && !tenant.coursepro_tenant && !tenant.homeportal_tenant && !tenant.hp2_tenant && !tenant.strive_tenant && !tenant.insight_tenant">


          <v-card color="rgb(152,152,152, 0.4)" shaped class="pa-4 ma-2">
            <v-card-title>
              No services configured for tenant. Why not add some?
            </v-card-title>
          </v-card>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12" sm="12" lg="12" v-if="tenant.coursepro_tenant">
          <v-card color="rgb(255,152,0, 0.4)" shaped class="pa-4 ma-2">

            <v-card-title>CoursePro
              <v-spacer />
              <v-btn right icon color="white" @click="editService('coursepro')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('coursepro')">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.coursepro_url" target="_blank">{{ tenant.coursepro_url
            }} <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>

            <v-card-text>
              <LicenceExpiryText v-bind:expiry="tenant.licence_expires"></LicenceExpiryText>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="editConfig('coursepro')">Config</v-btn>

              <v-btn :loading="coursepro_commit" :disabled="coursepro_commit"
                     v-if="tenant.config_hashes[1] != tenant.current_hashes[1]"
                     title="Coursepro config is different from the one available to servers"
                     @click="commitConfig('coursepro')">Commit</v-btn>

              <v-btn @click="editConfig('licence')">Licence</v-btn>
              <v-btn :loading="licence_commit" :disabled="licence_commit"
                     v-if="tenant.config_hashes[4] != tenant.current_hashes[4]"
                     title="Licence is different from the one available to servers" @click="commitConfig('licence')">Commit
                Licence</v-btn>

              <v-btn v-if="tenant.system" title="View system table (read only)"
                     :to="{ name: 'courseprosystem', params: { id: tenant._id } }">System table</v-btn>

              <v-btn title="View cron logs" :to="{ name: 'tenantcron', params: { id: tenant._id } }">Cron logs</v-btn>
              <v-btn v-if="tenant.strive_tenant && tenant.coursepro_tenant" title="Sync centres and available languages to strive" @click="centreSync()">Sync Centres and Languages</v-btn>

              <v-spacer/>
              <div>
                <p>Current Log Level: {{ tenant.log_level.current_level }}</p>
                <p v-if="hasUpcomingChange">
                  Log Level will change to:
                  {{ tenant.log_level.new_level }} in <span v-if="hasUpcomingChange">{{ countdown }}</span>
                </p>
                <p v-else>
                  Set to
                  <v-select
                      v-model="selectedLevel"
                      :items="levels"
                      outlined
                      dense
                      style="width: 140px; display: inline-block;"
                  ></v-select>
                  for
                  <v-select
                      v-model="selectedTime"
                      :items="times"
                      outlined
                      dense
                      style="width: 100px; display: inline-block;"
                  ></v-select>
                  mins
                  <v-btn @click="setFutureChange" class="ml-2">Set</v-btn>
                </p>
              </div>


            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>

        <v-col v-if="tenant.homeportal_tenant" cols="12" sm="12" lg="12">
          <v-card color="rgb(76,175,80,0.4)" shaped class="pa-4 ma-2">
            <v-card-title>HomePortal<v-spacer />
              <v-btn right icon color="white" @click="editService('homeportal')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('homeportal')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle>
              <a class="externalLink" :href="tenant.homeportal_url" target="_blank" v-if="tenant.homeportal_url">
                {{ tenant.homeportal_url }} <v-icon small>mdi-launch</v-icon>
              </a>
            </v-card-subtitle>
            <v-card-actions>
              <v-btn @click="editConfig('homeportal')">Config</v-btn>
              <v-btn :loading="homeportal_commit" :disabled="homeportal_commit"
                v-if="tenant.config_hashes[2] != tenant.current_hashes[2]"
                title="HomePortal config is different from the one available to servers"
                @click="commitConfig('homeportal')">Commit</v-btn>


            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="tenant.homeportal2_tenant" cols="12" sm="12" lg="12">
          <v-card color="rgb(63,81,181, 0.4)" shaped class="pa-4 ma-2">
            <v-card-title>HomePortal2<v-spacer />
              <v-btn right icon color="white" @click="editService('homeportal2')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('homeportal2')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.hp2_url" target="_blank">{{ tenant.hp2_url }} <v-icon
                  small>mdi-launch</v-icon></a></v-card-subtitle>
            <v-card-actions>
              <v-btn @click="editConfig('homeportal2')">Config</v-btn>
              <v-btn :loading="homeportal2_commit" :disabled="homeportal2_commit"
                v-if="tenant.config_hashes[3] != tenant.current_hashes[3]"
                title="HomePortal2 config is different from the one available to servers"
                @click="commitConfig('homeportal2')">Commit</v-btn>
              <v-btn @click="validateConfig('homeportal2')">Validate</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="tenant.coachportal_tenant" cols="12" sm="12" lg="12">
          <v-card color="rgb(92,205,92,0.6)" shaped class="pa-4 ma-2">
            <v-card-title>Coach Portal<v-spacer />
              <v-btn right icon color="white" @click="editService('coachportal')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('coachportal')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.coachportal_url" target="_blank">{{
              tenant.coachportal_url }} <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="tenant.organisationportal_tenant" cols="12" sm="12" lg="12">
          <v-card color="rgb(205,92,92)" shaped class="pa-4 ma-2">
            <v-card-title>Organisation Portal<v-spacer />
              <v-btn right icon color="white" @click="editService('organisationportal')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('organisationportal')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.organisationportal_url" target="_blank">{{
              tenant.organisationportal_url }} <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="tenant.schemeportal_tenant" cols="12" sm="12" lg="12">
          <v-card color="deep-purple lighten-1" shaped class="pa-4 ma-2">
            <v-card-title>Scheme Portal<v-spacer />
              <v-btn right icon color="white" @click="editService('schemeportal')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('schemeportal')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.schemeportal_url" target="_blank">{{
              tenant.schemeportal_url }} <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="tenant.parentportal_tenant" cols="12" sm="12" lg="12">
          <v-card color="rgb(92,92,205)" shaped class="pa-4 ma-2">
            <v-card-title>Parent Portal<v-spacer />
              <v-btn right icon color="white" @click="editService('parentportal')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('parentportal')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.parentportal_url" target="_blank">{{
              tenant.parentportal_url }} <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="tenant.strive_tenant" cols="12" sm="12" lg="12">
          <v-card color="deep-orange lighten-1" shaped class="pa-4 ma-2">
            <v-card-title>Strive<v-spacer />
              <v-btn right icon color="white" @click="editService('strive')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('strive')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.strive_url" target="_blank">{{ tenant.strive_url }}
                <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>
            <v-card-actions>
              <v-btn @click="editConfig('strive')">Config</v-btn>
              <v-btn :loading="strive_commit" :disabled="strive_commit"
                v-if="tenant.config_hashes[5] != tenant.current_hashes[5]"
                title="Strive config is different from the one available to servers"
                @click="commitConfig('strive')">Commit</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="tenant.insight_tenant" cols="12" sm="12" lg="12">
          <v-card color="brown" shaped class="pa-4 ma-2">
            <v-card-title>Insight<v-spacer />
              <v-btn right icon color="white" @click="editService('insight')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('insight')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.insight_url" target="_blank">{{ tenant.insight_url }}
                <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>
            <v-card-actions>
              <v-btn @click="editConfig('insight')">Config</v-btn>
              <v-btn :loading="insight_commit" :disabled="insight_commit"
                v-if="tenant.config_hashes[5] != tenant.current_hashes[5]"
                title="Insight config is different from the one available to servers"
                @click="commitConfig('insight')">Commit</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="tenant.commsportal_tenant" cols="12" sm="12" lg="12">
          <v-card color="blue-grey" shaped class="pa-4 ma-2">
            <v-card-title>Comms Portal<v-spacer />
              <v-btn right icon color="white" @click="editService('commsportal')">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn right icon color="white" @click="deleteService('commsportal')">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-title>
            <v-card-subtitle><a class="externalLink" :href="tenant.commsportal_url" target="_blank">{{ tenant.commsportal_url }}
                <v-icon small>mdi-launch</v-icon></a></v-card-subtitle>
            <v-card-actions>
              <v-btn @click="editConfig('commsportal')">Config</v-btn>
              <v-btn :loading="commsportal_commit" :disabled="commsportal_commit"
                v-if="tenant.config_hashes[5] != tenant.current_hashes[5]"
                title="Comms Portal config is different from the one available to servers"
                @click="commitConfig('commsportal')">Commit</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-btn absolute dark fab bottom left color="red darken-2"
        @click="deleteTenant()"><v-icon>mdi-delete</v-icon></v-btn>

      <v-speed-dial absolute dark fab bottom right open-on-hover>
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab v-if="showFab()">
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-btn v-if="!tenant.homeportal2_tenant">
          <v-chip color="indigo" outlined @click="addService('homeportal2')">
            HomePortal2&nbsp;
          </v-chip>
        </v-btn>

        <v-btn v-if="!tenant.coursepro_tenant">
          <v-chip color="orange" outlined @click="requestDb('coursepro')">
            CoursePro
          </v-chip>
        </v-btn>
        <v-btn v-if="!tenant.schemeportal_tenant">
          <v-chip color="deep-purple lighten-1" outlined @click="addService('schemeportal')">
            Scheme Portal
          </v-chip>
        </v-btn>

        <v-btn v-if="!tenant.coachportal_tenant">
          <v-chip color="green" outlined @click="addService('coachportal')">
            Coach Portal
          </v-chip>
        </v-btn>

        <v-btn v-if="!tenant.organisationportal_tenant">
          <v-chip color="red" outlined @click="addService('organisationportal')">
            Organisation Portal
          </v-chip>
        </v-btn>

        <v-btn v-if="!tenant.parentportal_tenant">
          <v-chip color="blue" outlined @click="addService('parentportal')">
            Parent Portal
          </v-chip>
        </v-btn>

        <v-btn v-if="!tenant.strive_tenant">
          <v-chip color="deep-orange lighten-1" outlined @click.exact="addService('strive')"
            @click.ctrl.exact="addService('strive', null, null, false)">
            Strive
          </v-chip>
        </v-btn>

        <v-btn v-if="!tenant.insight_tenant">
          <v-chip color="brown" outlined @click.exact="addService('insight')"
            @click.ctrl.exact="addService('insight', null, null, false)">
            Insight
          </v-chip>
        </v-btn>

        <v-btn v-if="!tenant.commsportal_tenant">
          <v-chip color="blue-grey" outlined @click.exact="addService('commsportal')"
            @click.ctrl.exact="addService('commsportal', null, null, false)">
            Comms Portal
          </v-chip>
        </v-btn>
      </v-speed-dial>
    </v-card>
    <v-dialog v-model="chooseDb" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Choose database</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-select
                  :items="databases"
                  v-model="chosenDb"
                  label="Choose db"
                  dense
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content :style="{ color: getColorFromAverage(item.db_activity_average) }">
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-row>
            <v-row>
              <v-select
                  :items="bridges"
                  v-model="chosenBridge"
                  label="Choose Bridge"
                  dense
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="chooseDb = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="selectedDb(chosenDb, chosenBridge)">Select</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import LicenceExpiryText from "@/components/LicenceExpiryText";
import TenantBreadcrumb from "@/components/TenantBreadcrumb";
import Bridge from "@/components/Bridge";

export default {
  components: { LicenceExpiryText, TenantBreadcrumb, Bridge },
  data() {
    return {
      tenantIsLoaded: false,
      fab: false,
      loader: null,
      homeportal_commit: false,
      homeportal2_commit: false,
      coursepro_commit: false,
      licence_commit: false,
      strive_commit: false,
      tenant: {
      },
      chooseDb: false,
      databases: [],
      bridges: [
        {text: 'Standalone', id: 'standalone'},
        {text: 'Gladstone', id: 'gladstone'},
        {text: 'Xn', id: 'xnrest'},
        {text: 'Legend', id: 'legend'},
        {text: 'Openplay', id: 'openplay'},
      ],
      chosenDb: false,
      chosenBridge: false,
      countdown: '',
      intervalId: null,
      selectedLevel: 'INFO', // Default level
      selectedTime: 15, // Default time period in minutes
      levels: ['DEBUG', 'INFO', 'NOTICE', 'WARNING', 'ERROR'],
      times: [1,5, 10, 15, 30, 60, 120, 180],
    }
  },
  computed: {

    hasUpcomingChange() {
      return !!(this.tenant && this.tenant.log_level && this.tenant.log_level.changes);
    },
  },
  watch: {
    tenantIsLoaded(newVal) {
      if (newVal && this.hasUpcomingChange) {
        this.startCountdown();
      } else {
        this.stopCountdown();
      }
    },
    hasUpcomingChange(newVal) {
      if (newVal && this.tenantIsLoaded) {
        this.startCountdown();
      } else {
        this.stopCountdown();
      }
    },
    'tenant.log_level.changes'() {
      if (this.hasUpcomingChange) {
        this.startCountdown();
      } else {
        this.stopCountdown();
      }
    },
    tenant: {
      handler() {
        if (this.hasUpcomingChange) {
          this.startCountdown();
        } else {
          this.stopCountdown();
        }
      },
      deep: true,
    }

  },
  beforeDestroy() {
    this.stopCountdown();
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id).then(response => {
      this.setTenant(response.data.tenant);
    });
  },
  methods: {
    formatDateTime(date) {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    async setFutureChange() {
      const now = new Date();
      const futureChangeTime = new Date(now.getTime() + this.selectedTime * 60000); // Add selected minutes to current UTC time
      const futureChangeTimeFormatted = this.formatDateTime(futureChangeTime);

      let payload = {
        "new": this.selectedLevel,
        "return":  futureChangeTimeFormatted
      };

      try {
        const response = await this.$http.post(
            process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/loglevel',
            payload
        );

        if (response.data && response.data.warning) {
          this.$toast.warning(response.data.warning);
        }

        const old = this.tenant.log_level.current_level;
        this.$set(this.tenant.log_level, 'current_level', this.selectedLevel);
        this.$set(this.tenant.log_level, 'new_level', old);
        this.$set(this.tenant.log_level, 'changes', futureChangeTimeFormatted);

        this.$forceUpdate();
      } catch (error) {
        this.$toast.error('Error setting the log level');
        console.error('Error setting the log level:', error);
      }
    },

    startCountdown() {
      this.stopCountdown(); // Clear any existing interval first
      const changeTime = new Date(this.tenant.log_level.changes.replace(' ', 'T') + 'Z'); // Ensure it's treated as UTC
      const now = new Date();

      let diffInMs = changeTime - now;
      this.countdown = this.getTimeUntilChange(diffInMs);

      this.intervalId = setInterval(() => {
        diffInMs -= 1000;
        if (diffInMs <= 0) {
          this.stopCountdown();
          this.$set(this.tenant.log_level, 'changes', null); // Reset the changes property
          this.$set(this.tenant.log_level, 'current_level', this.tenant.log_level.new_level);
          return;
        }
        this.countdown = this.getTimeUntilChange(diffInMs);
      }, 1000);
    },

    stopCountdown() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;

      }
      this.countdown = '';
    },

    getTimeUntilChange(diffInMs) {
      const seconds = Math.floor(diffInMs / 1000) % 60;
      const minutes = Math.floor(diffInMs / (1000 * 60)) % 60;
      const hours = Math.floor(diffInMs / (1000 * 60 * 60));

      let timeString = '';
      if (hours) {
        timeString += `${hours}h `;
      }
      if (minutes) {
        timeString += `${minutes}m `;
      }
      timeString += `${seconds}s`;

      return timeString;
    },

    getRelationType: function (type) {
      if (type == 1) {
        return 'Parent of';
      }
      if (type == 2) {
        return 'Child of';
      }
    },
    setTenant: function (tenant) {
      this.tenant = tenant;
      this.tenant.production = !this.tenant.staging;
      this.tenantIsLoaded = true;
    },
    showFab: function () {
      if (!this.tenant.coursepro_tenant) {
        return true;
      }
      if (!this.tenant.homeportal_tenant) {
        return true;
      }
      if (!this.tenant.homeportal2_tenant) {
        return true;
      }
      return false;
    },
    deployColor: function (group) {
      if (group === 'dev') {
        return 'red darken-4'
      }
      if (group === 'production') {
        return 'green darken-4'
      }
      if (group === 'staging') {
        return 'yellow darken-4'
      }
      return group + ' darken-4'
    },
    toggleCron: function () {
      this.$http.patch(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id,
        {
          cron_active: this.tenant.cron_active
        }
      ).then(response => {
        if (response.data.error) {
          this.$toast.error(response.data.error);
        }
        if (response.data.tenant) {
          this.setTenant(response.data.tenant);
        }

      });
    },
    toggleStatus: function () {
      this.$http.patch(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id,
        {
          status: this.tenant.status
        }
      ).then(response => {
        if (response.data.error) {
          this.$toast.error(response.data.error);
        }
        if (response.data.tenant) {
          this.setTenant(response.data.tenant);
        }

      });
    },
    toggleStaging: function () {
      this.$http.patch(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id,
        {
          staging: !this.tenant.production
        }
      ).then(response => {
        if (response.data.error) {
          this.$toast.error(response.data.error);
        }
        if (response.data.tenant) {
          this.setTenant(response.data.tenant);
        }

      });
    },
    switchEnvironmentGroup: function (group) {
      if (group === this.tenant.deployment_group) {
        return;
      }
      this.$dialog.confirm(
        'Confirm switch of environment from ' + this.tenant.deployment_group + ' to ' + group + ' for ' + this.tenant.tenant_name,
        {
          okText: 'CONFIRM',
          cancelText: 'Cancel',
          backdropClose: true,
          customClass: ''
        }
      )
        .then(() => {
          this.tenant.deployment_group = 'deploying.....'
          this.$http.patch(
            process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id,
            {
              deployment_group: group
            }
          ).then(response => {
            if (response.data.deployed) {
              this.$toast.success('Deployed to ' + response.data.deployed);
            }
            if (response.data.deployment_group) {
              this.tenant.deployment_group = response.data.deployment_group;
            }
          });
        })
        .catch(() => {

          // This will be triggered when user clicks on cancel
        });
    },
    editConfig: function (type) {
      this.$router.push('/tenant/' + this.$route.params.id + '/' + type + '/config');
    },
    openSandbox: function () {
      this.$router.push('/tenant/' + this.$route.params.id + '/sandbox');
    },
    openLeisureHub: function () {
      this.$router.push('/tenant/' + this.$route.params.id + '/leisurehub');
    },
    manageRelations: function () {
      this.$router.push('/tenant/' + this.$route.params.id + '/relations');
    },
    validateConfig: function (type) {
      this.$http.get(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/' + type + '/validate',
      ).then(response => {
        if (response.data.valid) {
          this.$toast.success('HomePortal2 Config is valid');
        } else {
          response.data.errors.forEach(row => this.$toast.error(row), this);
          response.data.warnings.forEach(row => this.$toast.warning(row), this);
        }
      });
    },
    commitConfig: function (type) {
      let commit = type + '_commit';
      this[commit] = true;
      const l = this.loader
      this[l] = !this[l]

      this.$http.put(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/' + type
      ).then(response => {
        this[commit] = false;
        if (response.data.type && response.data.hash) {
          this.tenant.config_hashes[response.data.type] = response.data.hash;
          this.$toast.success('Config successfully exported');
        }
      });

    },
    editService: function (type) {
      this.$router.push('/tenant/' + this.$route.params.id + '/' + type);
    },
    deleteService: function (type) {
      this.$dialog.confirm(
        'Confirm deletion of ' + type + ' service for ' + this.tenant.tenant_name,
        {
          okText: 'DELETE',
          cancelText: 'Cancel',
          type: 'hard', // coming soon: 'soft', 'hard'
          verification: this.tenant.tenant_name,
          verificationHelp: 'Type the tenant name below to confirm',
          backdropClose: true,
          customClass: ''
        }
      )
        .then(() => {
          this.$http.delete(
            process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/' + type
          ).then(response => {
            if (response.data.status) {
              this.$toast.success(type + ' service deleted');
            } else {
              this.$toast.error(type + ' service could not be deleted');
            }
            this.setTenant(response.data.tenant);
          });

          // lic
        })
        .catch(() => {
          // This will be triggered when user clicks on cancel
        });
    },
    getColorFromAverage(average) {
      const avg = parseFloat(average);
      if (avg < 4) return '#32a852'; // Green
      else if (avg < 10) return '#ffffff'; // White
      else if (avg < 20) return '#e6810e'; // Orange
      else return '#f5022b'; // Red
    },
    selectedDb(chosenDb, chosenBridge) {
      let bridge = this.bridges.find(item => item.text === chosenBridge);
      let database = this.databases.find(item => item.value === chosenDb);
      this.addService(database.type, database.value, bridge.id);
      this.chooseDb = false;
    },
    requestDb: async function(type) {
      let response = await this.$http.get(
          process.env.VUE_APP_CONTROL_API_URL + "database/",
          {
            initialize: true
          }
      );
      if (response.data.error) {
        this.$toast.error(response.data.error);
      } else {
        const databasesObject = response.data;
        this.databases = databasesObject.databases.map(db => ({
            "text": `${db.name}${db.notes ? ` (${db.notes})` : ''} [${db.db_activity_average}]`,
            "value": db.id,
            "type": type,
            "db_activity_average": db.db_activity_average
        }));

        this.chooseDb = true;
      }
    },
    addService: function (type, db = null, bridge=null, createDb = true) {
      this.$http.post(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/' + type,
        {
          initialize: true,
          create_db: createDb,
          database: db,
          bridge: bridge
        }
      ).then(response => {
        if (response.data.error) {
          this.$toast.error(response.data.error);
        } else {
          this.$toast.success(type + ' added');
          this.setTenant(response.data.tenant);
        }
      });

    },
    deleteTenant: function () {
      this.$dialog.confirm(
        'Confirm destruction of ' + this.tenant.tenant_name + '. Please ensure you have a backup of the database before continuing',
        {
          okText: 'DELETE',
          cancelText: 'Cancel',
          type: 'hard', // coming soon: 'soft', 'hard'
          verification: this.tenant.tenant_name,
          verificationHelp: 'Type the tenant name below to confirm',
          backdropClose: true,
          customClass: ''
        }
      )
        .then(() => {
          this.$http.delete(
            process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id
          ).then(response => {
            if (response.data.status) {
              this.$toast.success(this.tenant.tenant_name + ' deleted');
              this.$router.push('/tenant');
            } else {
              this.$toast.error(this.tenant.tenant_name + ' could not be deleted');
            }
          });

        })
        .catch(() => {
          // This will be triggered when user clicks on cancel
        });
    },
    centreSync() {
      this.$http.get(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/coursepro/centre-sync'
      ).then(response => {
        if (response.data.ok) {
          this.$toast.success('Centres and languages synced to strive');
        } else {
          this.$toast.error('Error: ' + response.data.message);
        }
      });
    },
  },
  name: "tenant"
}
</script>

<style scoped>
a {
  color: white;
}

[v-cloak] {
  display: none;
}
</style>
