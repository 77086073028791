<template>
  <v-chip
      v-bind="$attrs"
      class="ma-2"
      small
     :color=chipColor()
  >
                  <v-avatar
                      left
                      v-if="$props.expiry !== null"
                  >
                    <v-icon>{{ chipIcon() }}</v-icon>
                  </v-avatar>
    {{ chipText() }}
  </v-chip>
</template>

<script>
export default {
  name: 'LicenceExpiry',
  data () {
    return {
      days: undefined,
      state: undefined
    }
  },
  props: {
    expiry: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const date1 = new Date(this.$props.expiry);
    const date2 = new Date();
    const days =  ( date1.getTime() - date2.getTime())/ (1000 * 3600 * 24);
    if (days < 0) {
      this.state = -1;
    } else if (days < 91) {
      this.state = 0;
    } else {
      this.state = 1;
    }
  },

  methods: {
    chipColor() {
      if (this.$props.expiry === null) {
        return '';
      }
      if (this.$props.expiry === '') {
        return 'success';
      }
      switch(this.state) {
        case -1:
          // expired
          return 'error';
        case 0:
          // expiring in 90 days
          return 'warning';
        case 1:
        default:
          return 'success';
          // code block
      }
    },
    chipText() {
      if (this.$props.expiry === null) {
        return 'No licence';
      }
      if (this.$props.expiry === '') {
        return 'Infinity';
      }
      return this.$props.expiry;
    },
    chipIcon() {
      if (this.$props.expiry === null) {
        return '';
      }
      if (this.$props.expiry === '') {
        return 'mdi-infinity';
      }
      switch(this.state) {
        case -1:
          return 'mdi-block-helper';
        case 0:
          // expiring in 90 days
          return 'mdi-alert-circle';
        case 1:
        default:
          return 'mdi-thumb-up';
          // code block
      }
    },
  },
}
</script>

<style lang="sass">
</style>
