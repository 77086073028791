<template>
    <v-layout child-flex>
    <v-card>
        <v-card-title>
            Tenants
          <v-spacer></v-spacer>
          <v-select
              :items="filters"
              label="Filter"
              v-model="filter"

          ></v-select>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details

            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="tenantsData"
                :search="search"
                :items-per-page="10"
                class="elevation-1"
                :loading="loading"
        >
            <template v-slot:item.products="{ item }">
                <v-chip v-if=item.coursepro_tenant
                        class="ma-2"
                        small
                        color="orange"
                        :to="{ name: 'coursepro', params: { id: item._id }}"
                >
                    CP
                </v-chip>
                <v-chip v-if=item.homeportal_tenant
                        class="ma-2"
                        small
                        color="green"
                        :to="{ name: 'homeportal', params: { id: item._id }}"
                >
                    HP1
                </v-chip>
                <v-chip v-if=item.homeportal2_tenant
                        class="ma-2"
                        small
                        color="indigo"
                        :to="{ name: 'homeportal2', params: { id: item._id }}"
                >
                    HP2
                </v-chip>
                <v-chip v-if=item.cron_active
                        class="ma-2"
                        small
                        color="default"
                        :to="{ name: 'tenant', params: { id: item._id }}"
                >
                    Cron
                </v-chip>
            </template>
          <template v-slot:item.status="{ item }">
            <v-chip
                class="ma-2"
                color="green"
                small
                v-if="item.tenant_status=='1'"
            >
              Yes
            </v-chip>
            <v-chip
                class="ma-2"
                color="red"
                small
                v-if="item.tenant_status=='0'"
            >
              No
            </v-chip>
          </template>
          <template v-slot:item.licence_expires="{ item }">
            <LicenceExpiry
                v-bind:expiry="item.licence_expires"
            ></LicenceExpiry>
          </template>

          <template v-slot:item.total_members="{ item }">
            <span v-if="item.total_members">
              {{ item.total_members.toLocaleString() }}
            </span>
            <span v-else>
              n/a
            </span>
          </template>

          <template v-slot:item.coursepro_bridge="{ item }">
            <Bridge
                v-bind:bridge="item.coursepro_bridge"
                v-bind:version="item.coursepro_version"
            ></Bridge>
          </template>
          <template v-slot:item.deployment_group="{ item }">
            <v-chip
                class="ma-2"
                color="red darken-4"
                v-if="item.deployment_group=='dev'"
            >
              DEV
            </v-chip>
            <v-chip
                class="ma-2"
                color="yellow darken-4"
                v-if="item.deployment_group=='staging'"
            >
              STAGE
            </v-chip>
            <v-chip
                class="ma-2"
                color="green darken-4"
                v-else-if="item.deployment_group=='production'"
            >
              PROD
            </v-chip>
            <v-chip
                class="ma-2"
                v-else-if="item.deployment_group"
            >
              {{item.deployment_group.toUpperCase()}}
            </v-chip>

          </template>
            <template v-slot:item.actions="{ item }">
                <v-chip
                        class="ma-2"
                        :to="{ name: 'tenant', params: { id: item._id }}"
                        color="primary"
                >
                    View
                </v-chip>
            </template>
        </v-data-table>
      <v-card-actions>
        <v-btn @click="tenantListCacheBust()">Refresh</v-btn>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                bottom
                right
                color="pink"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add tenant</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                <v-row>
                  <v-text-field v-model="newTenant.tenant" label="Name"></v-text-field>
                </v-row>
                <v-row>
                  <v-select
                      :items="regions"
                      v-model="newTenant.region"
                      label="Region"
                      dense
                  ></v-select>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>

    </v-card>
    </v-layout>
</template>





<script>
    import LicenceExpiry from "@/components/LicenceExpiry";
    import Bridge from "@/components/Bridge";
    export default {
      components: {LicenceExpiry, Bridge},
      data () {
            return {
              filter: null,
              filters: [
                {text: 'No filter', value: null},
                  {text: 'Active', value: 'tenant_status:1'},
                  {text: 'Inactive', value: 'tenant_status:0'},
              ],
                search: '',
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'tenant_name',
                    },
                  { text: 'Active', value: 'status'},
                    { text: 'Products', value: 'products', sortable: false },
                    // { text: 'Region', value: 'region' },
                    { text: 'Deployment', value: 'deployment_group' },
                    { text: 'Version', value: 'coursepro_version' },
                    { text: 'DB Version', value: 'db_version' },
                    { text: 'DB Host Label', value: 'db_host_label' },
                    { text: 'Bridge', value: 'coursepro_bridge' },
                    { text: 'Licence', value: 'licence_expires' },
                  { text: 'Members', value: 'total_members' },
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
              regions: [
                {'text': 'Dublin', value: 1},
                {'text': 'Sydney', value: 2},
              ],
                tenants: [],
                loading: true,
                dialog: false,
               newTenant: {
                  tenant : '',
                  region: 1
               },
            }
        },
        mounted() {
            this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant").then(response => {

                this.tenants = response.data.tenants;
                this.makeFilters();
                this.loading = false;
            });
        },
      computed: {
        tenantsData() {
          if (this.filter) {
            let parts = this.filter.split(':');
            return this.tenants.filter(function (item) {
              return item[parts[0]] === parts[1];
            }, parts);
          }
          return this.tenants;
        }
      },
      methods: {
        normalizeMembers: function(total)
        {
          console.log('Got '+total+ ' normal: '+ Math.floor(total/1000)/2);
          return parseFloat(Math.floor(total/1000)/2);
        },
        makeFilters: function()
        {
          this.filters = [
            {text: 'No filter', value: null},
            {text: 'Active', value: 'tenant_status:1'},
            {text: 'Inactive', value: 'tenant_status:0'},
          ];
          [...new Set(this.tenants.map(a => a.region))].forEach(value => {
            this.filters.push({
              text: 'Region: '+value,
              value:'region:'+value
            })
          });
          [...new Set(this.tenants.map(a => a.deployment_group))].forEach(value => {
            this.filters.push({
              text: 'Group: '+value,
              value:'deployment_group:'+value
            })
          });
          [...new Set(this.tenants.map(a => a.coursepro_version))].forEach(value => {
            this.filters.push({
              text: 'Version: '+value,
              value:'coursepro_version:'+value
            })
          });
          [...new Set(this.tenants.map(a => a.db_version))].forEach(value => {
            this.filters.push({
              text: 'DB: '+value,
              value:'db_version:'+value
            })
          });
          [...new Set(this.tenants.map(a => a.db_host_label))].forEach(value => {
            this.filters.push({
              text: 'DB Label: '+value,
              value:'db_host_label:'+value
            })
          });
          [...new Set(this.tenants.map(a => a.coursepro_bridge))].forEach(value => {
            this.filters.push({
              text: 'Bridge: '+value,
              value:'coursepro_bridge:'+value
            })
          });

          [...new Set(this.tenants.map(a => a.cpweb_name))].forEach(value => {
              this.filters.push({
                text: 'CP Env: '+value,
                value:'cpweb_name:'+value
              })
            });



        },
        tenantListCacheBust: function(){
          this.$http.get(
              process.env.VUE_APP_CONTROL_API_URL + "tenant/refresh"
          ).then(response => {
            console.log(response);
            this.loading = true
            this.tenants = [];
            this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant").then(response => {
              this.tenants = response.data.tenants;
              this.loading = false;
            });
          });
        },
        save(){
          this.$http.put(
              process.env.VUE_APP_CONTROL_API_URL + "tenant/",
              this.newTenant
          ).then(response => {
            this.$toast.success('Successfully created tenant `'+response.data.tenant+'`');
            this.$router.push('/tenant/'+response.data.id);
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.$toast.error(error.response.data.msg);
            }
          });
          this.close()
        },
        close(){
          this.dialog = false;
        },
      },
        name: "tenant-list"
    }

</script>

<style scoped>

</style>
