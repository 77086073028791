<template>
  <v-chip
      class="ma-2"
            :color=spanClass()
  >
    {{ spanText() }}
  </v-chip>
</template>

<script>
export default {
  name: 'LicenceExpiryText',
  data () {
    return {
      state: undefined,
      days: undefined
    }
  },
  props: {
    expiry: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const date1 = new Date(this.$props.expiry);
    const date2 = new Date();
    this.days =  (( date1.getTime() - date2.getTime())/ (1000 * 3600 * 24));
    if (this.days < 0) {
      this.state = -1;
    } else if (this.days < 91) {
      this.state = 0;
    } else {
      this.state = 1;
    }
  },

  methods: {
    spanClass() {
      if (this.$props.expiry === null) {
        return '';
      }
      if (this.$props.expiry === '') {
        return 'success';
      }
      switch(this.state) {
        case -1:
          case 0:
          // expired or expiring soon
          return 'error';
        case 1:
        default:
          return 'success';
          // code block
      }
    },
    spanText() {
      if (this.$props.expiry === null) {
        return 'No licence';
      }
      if (this.$props.expiry === '') {
        return 'Licence never expires';
      }
      switch(this.state) {
        case -1:
          // expired
          return 'Licence expired on '+  this.$props.expiry;
        case 0:
          // expiring in 90 days
          return 'Licence expires in  '+ this.days.toFixed(0) + ' days (' +  this.$props.expiry + ')';
        case 1:
        default:
          return 'Licence expires on ' +  this.$props.expiry;
          // code block
      }
    },
  },
}
</script>

<style lang="sass">

</style>
