<template>
  <v-chip
      v-if="chipText()"
      v-bind="$attrs"
      class="ma-2 bridge"
      small
     :color=chipColor()
  >
                  <v-avatar
                      left
                      v-if="$props.expiry !== null"
                  >
                    <v-icon>mdi-bridge</v-icon>
                  </v-avatar>
    {{ chipText() }}
  </v-chip>
</template>

<script>
export default {
  name: 'Bridge',
  props: {
    bridge: {
      type: String,
      default: '',
    },
    version: {
      type: String,
      default: '',
    },
  },
  methods: {
    chipColor() {
      return '';
    },
    chipText() {
      if (this.$props.bridge ) {
        return this.$props.bridge;
      }
      if (this.$props.version ) {
        return 'standalone';
      }
      return '';
    },
  },
}
</script>
<style scoped>
.bridge {
  text-transform: capitalize;
}
</style>

