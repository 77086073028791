var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Tenants "),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.filters,"label":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tenantsData,"search":_vm.search,"items-per-page":10,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.products",fn:function(ref){
var item = ref.item;
return [(item.coursepro_tenant)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"orange","to":{ name: 'coursepro', params: { id: item._id }}}},[_vm._v(" CP ")]):_vm._e(),(item.homeportal_tenant)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"green","to":{ name: 'homeportal', params: { id: item._id }}}},[_vm._v(" HP1 ")]):_vm._e(),(item.homeportal2_tenant)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"indigo","to":{ name: 'homeportal2', params: { id: item._id }}}},[_vm._v(" HP2 ")]):_vm._e(),(item.cron_active)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"default","to":{ name: 'tenant', params: { id: item._id }}}},[_vm._v(" Cron ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.tenant_status=='1')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","small":""}},[_vm._v(" Yes ")]):_vm._e(),(item.tenant_status=='0')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","small":""}},[_vm._v(" No ")]):_vm._e()]}},{key:"item.licence_expires",fn:function(ref){
var item = ref.item;
return [_c('LicenceExpiry',{attrs:{"expiry":item.licence_expires}})]}},{key:"item.total_members",fn:function(ref){
var item = ref.item;
return [(item.total_members)?_c('span',[_vm._v(" "+_vm._s(item.total_members.toLocaleString())+" ")]):_c('span',[_vm._v(" n/a ")])]}},{key:"item.coursepro_bridge",fn:function(ref){
var item = ref.item;
return [_c('Bridge',{attrs:{"bridge":item.coursepro_bridge,"version":item.coursepro_version}})]}},{key:"item.deployment_group",fn:function(ref){
var item = ref.item;
return [(item.deployment_group=='dev')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red darken-4"}},[_vm._v(" DEV ")]):_vm._e(),(item.deployment_group=='staging')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"yellow darken-4"}},[_vm._v(" STAGE ")]):(item.deployment_group=='production')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green darken-4"}},[_vm._v(" PROD ")]):(item.deployment_group)?_c('v-chip',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(item.deployment_group.toUpperCase())+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"to":{ name: 'tenant', params: { id: item._id }},"color":"primary"}},[_vm._v(" View ")])]}}])}),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.tenantListCacheBust()}}},[_vm._v("Refresh")]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"pink"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add tenant")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.newTenant.tenant),callback:function ($$v) {_vm.$set(_vm.newTenant, "tenant", $$v)},expression:"newTenant.tenant"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.regions,"label":"Region","dense":""},model:{value:(_vm.newTenant.region),callback:function ($$v) {_vm.$set(_vm.newTenant, "region", $$v)},expression:"newTenant.region"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }