<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title> Dashboard
        <v-spacer></v-spacer>
<!--        <v-combobox-->
<!--            :items="stats.tenants"-->
<!--            v-model="selectedTenant"-->
<!--            label=""-->
<!--            v-on:change="refreshStats"-->
<!--        ></v-combobox>-->

          <v-select
              :items="stats.tenants"
              v-model="selectedTenant"
              label=""
              v-on:change="refreshStats"
          ></v-select>
      </v-card-title>
      <v-row>



        <v-col
            cols="12"
            sm="6"
            lg="4"
        >
          <base-material-stats-card
              color="green"
              icon="mdi-account-circle"
              title="Members On Course"
              v-bind:value="stats.member.oncourse.toLocaleString('en')"
              v-if="stats.member.oncourse !== undefined"
          />
        </v-col>

        <v-col
            cols="12"
            sm="6"
            lg="4"
        >
          <base-material-stats-card
              color="warning"
              icon="mdi-home-circle"
              title="HomePortal Members On Course"
              v-bind:value="stats.member.homeportal_oncourse.toLocaleString('en')"
              v-if="stats.member.homeportal_oncourse !== undefined"
          />
        </v-col>

        <v-col
            cols="12"
            sm="6"
            lg="4"
        >
          <base-material-stats-card
              color="info"
              icon="mdi-percent-outline"
              title="HomePortal %age"
              v-bind:value="(stats.member.homeportal_oncourse/stats.member.oncourse * 100).toFixed(2) +'%'"
          />


        </v-col>
      </v-row>
    <v-row>
        <v-col
            cols="12"
            sm="6"
            lg="4"
        >
          <base-material-stats-card
              color="green"
              icon="mdi-account-circle"
              title="Total Members"
              v-bind:value="stats.member.all.toLocaleString('en')"
          />
        </v-col>
      <v-col
          cols="12"
          sm="6"
          lg="4"
      >
        <base-material-stats-card
            color="info"
            icon="mdi-percent-outline"
            title="Not OnCourse %age"
            v-bind:value="(100 - (stats.member.oncourse/(stats.member.all) * 100)).toFixed(2) +'%'"
        />
      </v-col>
</v-row>


      <v-row>


        <v-col
            cols="12"
            sm="6"
            lg="6"
        >          <v-card
              outlined
              class="pa-2"
          >
            <v-card-title>Gender Split
            </v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-card-text>
              <Gender v-bind:chartData="stats.gender"></Gender>

            </v-card-text>
            <v-card-actions>

            </v-card-actions>
          </v-card>
        </v-col>
       <v-col
          cols="12"
          sm="6"
          lg="6"
      >
          <v-card
              outlined
              class="pa-2"
          >
            <v-card-title>Age Distribution
            </v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-card-text>

              <Ages v-bind:chartData="stats.ages"></Ages>
            </v-card-text>
            <v-card-actions>

            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            lg="6"
            v-if="selectedTenant == null"
        >          <v-card
            outlined
            class="pa-2"
        >
          <v-card-title>Member Distribution
          </v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text>
            <MemberDistribution v-bind:chartData="stats.member.distribution"></MemberDistribution>

          </v-card-text>
          <v-card-actions>

          </v-card-actions>
        </v-card>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            lg="6"
            v-if="selectedTenant == null"
        >          <v-card
            outlined
            class="pa-2"
        >
          <v-card-title>SDD Errors
          </v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="stats.sdderrors"
                :item-class= "sddErrorRowClass"
                class="elevation-1"
            >
              <template v-slot:item.level="{ item }">
                <v-chip
                    :color="getColor(item.level)"
                    dark
                >
                  {{ item.level }}
                </v-chip>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-layout>

</template>

<script>
import Gender from "@/components/charts/Gender";
 import Ages from "@/components/charts/Ages";
 import MemberDistribution from "@/components/charts/MemberDistribution";


export default {
  data() {
    return {
      stats: {
        tenants: [{ text: "All production", value:null}],
        gender: {},
        ages: {},
        sdderrors: {},
        member: {
          all: 0,
          homeportal: 0,
        },


      },
      headers: [
        {
          text: 'Name',
          value: 'tenant',
        },
        { text: 'Message', value: 'display' },
        { text: 'Date', value: 'timestamp' },
        { text: 'Level', value: 'level' },
      ],
      selectedTenant: null,
    }
  },
  name: "dashboard",
  components: {
     Ages,
    Gender,
    MemberDistribution
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/tenants").then(response => {
      response.data.data.forEach(function (row) {
        this.stats.tenants.push({
          text: row.tenant_name,
          value: row.id
        });
      }, this);
    });
    this.refreshStats();
    // this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/gender").then(response => {
    //
    //   this.stats.gender = response.data.data;
    // });
    // this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/age").then(response => {
    //   this.stats.ages = response.data.data;
    // });
    // this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/member").then(response => {
    //   this.stats.member = response.data.data;
    // });

  },
  methods: {
    refreshStats: function () {
      let suffix = '';
      if (this.selectedTenant) {
        suffix = '?tenant='+this.selectedTenant;
      }
      this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/gender"+suffix).then(response => {
        this.stats.gender = response.data.data;
      });
      this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/age"+suffix).then(response => {
        this.stats.ages = response.data.data;
      });
      this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/member"+suffix).then(response => {
        this.stats.member = response.data.data;
      });
      this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/sdd-errors").then(response => {
        this.stats.sdderrors = response.data.data;
      });
    },
    getColor: function(item) {
      if (item == 'ERROR'){
        return 'red';
      }
      return 'orange'
    }
  }
}
</script>

<style scoped>

</style>
